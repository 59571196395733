<template>
	<div class="Supervision">
		<w-navTab titleText="监督须知"></w-navTab>
		<div class="Supervision-head">
			<img v-if="isCity()" src="@/assets/img/icon58.png" alt="" />
			<img v-else src="@/assets/img/icon97.jpg" alt="" />
		</div>
		<div class="Supervision-body">
			<div class="body">
				<div class="body-title conner">上饶市平安义警行为规范及服务守则</div>
				<div class="body-text">
					为了规范平安义警和平安志愿者的着装仪容、言行举止和服务要求等内容，树立“上饶义警”的良好形象，维护“上饶义警”的美好声誉，特制定本行为规范及服务守则。
				</div>
				<div class="body-title">一、证件佩戴与使用 </div>
				<div class="body-text">（一）义警或志愿者上岗时应着义警服装或志愿者马甲并佩戴义警标识。</div>
				<div class="body-text">（二）个人应妥善保管工作证、标识，证件仅限持证人本人使用，不得转借、涂改，不得冒用伪造他人证件。</div>
				<div class="body-title">二、着装规范与仪容 </div>
				<div class="body-text">（一）义警或志愿者应当仪态端正、卫生整洁、妆容得体。男士不得蓄胡须、留长发和留长指甲；女士不得披长发或散发、浓妆艳抹，留长发者需将头发束扎或盘起。</div>
				<div class="body-text">（二）按照协会统一标准穿戴义警服装或志愿者马甲，做到整洁、配套、规范。随身佩戴证件，不得佩戴、系挂与义警工作无关的标识或物品。 </div>
				<div class="body-text">（三）义警服装或志愿者马甲只能在开展义警工作时段穿着，不得私下随意改裁、涂鸦，更不得将服装转做他用，从事非协会组织的公益活动以外的工作。 </div>
				<div class="body-title">三、五个严禁</div>
				<div class="body-text">（一）严禁在政治上、思想上有任何违背党和国家的言论及意见。</div>
				<div class="body-text">（二）严禁着义警服装进入餐饮、休闲等娱乐场所进行私人活动。</div>
				<div class="body-text">（三）严禁以义警身份不作为乱作为、耍特权抖威风。</div>
				<div class="body-text">（四）严禁以义警名义发布不实、不良、不当信息。</div>
				<div class="body-text">（五）严禁以义警名义违规谋取不当私利。</div>
				<div class="body-title">四、行为养成</div>
				<div class="body-text">（一）自觉遵守工作要求，做到规范工作、文明服务。</div>
				<div class="body-text">（二）在公众场所应当保持良好形象，不得出现吸烟、吃零食、歪立斜坐等行为。</div>
				<div class="body-text">（三）不得擅自离岗，在岗时不做与工作或任务无关的事情。</div>
				<div class="body-text">（四）在上下岗路途中应当保持良好作风形象，做到2人成行、3人成伍。</div>
				<div class="body-text">（五）不得擅自接受媒体采访或在网络上发表不良言论。</div>
				<div class="body-text">（六）不得违规接受、索取服务对象的现金或物品馈赠。 </div>
				<div class="body-text">（七）保持通讯畅通，遇到棘手困难时，应当及时上报，避免错过处置时机，造成不良后果。 </div>
				<div class="body-text">（八）不准损毁公共设施、践踏草坪，不准攀爬建筑、翻越围（护）栏，不准携带各类危险品执行任务。</div>
				<div class="body-title">五、服务守则 </div>
				<div class="body-text">（一）依法服务，文明服务，热情服务，耐心服务。</div>
				<div class="body-text">（二）遇到党政机关和部门执法时，应当主动配合。</div>
				<div class="body-text">（三）熟悉服务所在岗位的职责要求和注意事项。</div>
				<div class="body-text">（四）服从命令，听从指挥，在任务区域应当服从辖区所属义警协会的管理。</div>
				<div class="body-text">（五）注重团队协作，警友之间要做到团结友爱、互帮互助。</div>
				<div class="body-text">（六）严守时间规定，做到准时上岗。因故需请假的，应提前报告。</div>
				<div class="body-text">（七）坚守本职岗位，严格履行职责。</div>
			</div>
		</div>
		<div class="Supervision-footer">
			<div class="footer-button" v-if="time == 0" @click="toAddSupervision">前往上报</div>
			<div class="footer-button" v-else>我已阅读（{{time}}秒）</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Supervision',
		inject: ['themeColor', 'className', 'isCity', 'isDistrict'],
		data() {
			return {
				time: 10,
			};
		},
		created() {
			let timeText = setInterval(() => {
				let time = this.time
				time--
				this.time = time
				if (this.time == 0) {
					clearTimeout(timeText)
				}
			}, 1000)

		},
		methods: {
			toAddSupervision(){
				this.$router.push({
					name: 'AddSupervision'
				});
			}
		}
	};
</script>

<style scoped lang="less">
	.Supervision.city{
		.Supervision-footer{
			.footer-button{
				background: #3377FF;
			}
		}
	}
	
	.Supervision.district{
		.Supervision-footer{
			.footer-button{
				background: #FF6969;
			}
		}
	}
	
	.Supervision {
		width: 100%;
		min-height: 100vh;
		padding-bottom: 65px;
		box-sizing: border-box;

		.Supervision-head {
			width: 100%;
			height: 319px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.Supervision-body {
			margin: 0 8px;
			background: #fff;
			border-radius: 6px;
			margin-top: -87px;
			position: relative;
			z-index: 5;

			.body {
				padding: 12px;

				.body-title {
					font-weight: bold;
					font-size: 15px;
					line-height: 22px;
					color: #1c1d1d;
					margin-top: 16px;

					&.body-title:first-child {
						margin-top: 0;
					}
				}
				.conner{
					text-align: center;
				}
				.body-text {
					font-size: 15px;
					line-height: 22px;
					text-align: justify;
					text-indent: 28px;
					color: #44484e;
					margin-top: 8px;
				}
			}
		}
	
		.Supervision-footer {
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 8px;
			width: 100%;
			box-sizing: border-box;
			z-index: 10;

			.footer-button {
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				padding: 11px;
				color: #fff;
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}
</style>
